<div class="content">
  <div *ngFor="let blog of blogs.data" class="blog-item">
      <img src={{blog.image}} alt={{blog.title}}>

      <div class="contents">
          <h4 class="title">{{blog.attributes.title}}</h4>
          <p class="author">{{blog.attributes.author}}</p>
          <div class="body">{{ strip(blog.attributes.body.value) }}</div> 
          <!-- <div class="body" [innerHTML]="blog.attributes.body.value"></div>   -->

          <div class="rm-container">
              <button (click)="goToArticle( blog.id )" class="read-more">Read More</button> 
          </div>
      </div>
  </div>
</div>