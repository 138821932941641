import { Component, OnInit, Input } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  blog: any;

  constructor(private apiService: ApiServiceService, private route: ActivatedRoute) {
    const id = this.route.snapshot.paramMap.get('id');
    
    this.apiService.getSingleNode(id).subscribe((data) => {
      this.blog = data;
    });
   }

   ngOnInit(): void {
  }
}
