import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  API_KEY = '784bdb7f8e16d58d0e087aa39a16e7cb';

  constructor(private httpClient: HttpClient) { }

  public getArticles() {
    return this.httpClient.get(`http://dev-kalasplit.at.kalamuna.com/api/articles`);
  }

  public getSingleNode(id: string) {
    return this.httpClient.get(`http://dev-kalasplit.at.kalamuna.com/api/articles/${id}`);
  }
}