import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../api-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  blogs: any;

  constructor(private apiService: ApiServiceService, private router: Router) {
    this.apiService.getArticles().subscribe((data) => {
      this.blogs = data;
    });
   }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }

  strip(text: string) {
    text = text.replace(/<[^>]*>/g, '');
    text = text.substring(0, 300);
    return text + '...';
  }

  goToArticle(id: any): void {
    this.router.navigateByUrl('/article/' + id);
  }

}